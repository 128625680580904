/**
 * @license
 * Copyright 2020 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import {ENGINE, ForwardFunc} from '../engine';
import {Elu, EluGrad, EluGradInputs} from '../kernel_names';
import {GradConfig} from '../kernel_registry';
import {Tensor} from '../tensor';
import {NamedTensorMap} from '../tensor_types';

export const eluGradConfig: GradConfig = {
  kernelName: Elu,
  outputsToSave: [true],
  gradFunc: (dy: Tensor, saved: Tensor[]) => {
    const [y] = saved;

    const backPropKernelFunc: ForwardFunc<Tensor> = (backend) => {
      return backend.eluDer(dy, y);
    };

    const inputs: EluGradInputs = {dy, y};

    return {
      x: () => ENGINE.runKernelFunc(
          backPropKernelFunc, inputs as {} as NamedTensorMap, null /* grad */,
          EluGrad)
    };
  }
};
